import React from "react";
// Customizable Area Start
import { Typography, Box, Tooltip, Radio } from "@mui/material";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import dayjs, { Dayjs } from "dayjs";
import { errorIcon, addIcon, removeIcon } from "./assets";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// Customizable Area End

import AddTenantPageController, {
  Props,
  configJSON,
} from "./AddTenantPageController";
// Customizable Area Start
// Customizable Area End
export default class AddTenantPage extends AddTenantPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  meterListSchema = () => {
    return Yup.object().shape({
      type: Yup.string(),
      meterNumber: Yup.string().required("Meter Number is required"),
      lastReading: Yup.string()
        .matches(/(\-?\d*\.?\d+)/, "Must be only digits")
        .required("Last Reading is required"),
      lastReadingDate: Yup.string().required("Tenant Name is required"),
      // meteerNote: Yup.string().required("Tenant Name is required"),
    });
  };

  addTenantSchema = () => {
    return Yup.object().shape({
      tenantName: Yup.string().required("Tenant Name is required"),
      meters: Yup.array().of(this.meterListSchema()).notRequired(),
    });
  };

  addMeterSchema = () => {
    return Yup.object().shape({
      meters: Yup.array().of(this.meterListSchema()).notRequired(),
    });
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.addTenantsContainer}>
        <Box sx={webStyle.addTenantsBlock}>
          <Box sx={webStyle.addTenantFormContainer}>
            <Box>
              <Typography sx={webStyle.formTitle}>
                {this.state.isEditTenant || this.state.isEditMeter 
                  ? (this.state.isTenant 
                    ? configJSON.editTenantText 
                    : configJSON.editMeterText)
                  : (this.state.isTenant
                    ? this.state.currentProperty.property_name
                    : this.state.currentTenant.tenant_name)
                }
              </Typography>
              <Typography sx={webStyle.formSubTitle}>
                {(this.state.isTenant && !this.state.isEditMeter && !this.state.isEditTenant)
                  ? this.state.currentProperty.service_address
                  : ""}
              </Typography>
            </Box>
            <Formik
              initialValues={{
                tenantName: this.state.isEditTenant
                  ? this.state.currentTenant?.tenant_name
                  : "",
                meters: this.state.isEditTenant
                  ? []
                  : [
                      {
                        type: this.state.isEditMeter
                          ? this.state.currentMeter?.meter_type
                          : "Water",
                        waterSubType: this.state.isEditMeter
                          ? this.state.currentMeter?.water_sub_type
                          : "Cold",
                        volume: this.state.isEditMeter
                          ? this.state.currentMeter?.volume
                          : "Cu ft",
                        metric: this.state.isEditMeter
                          ? this.state.currentMeter?.metric
                          : "Standard",
                        meterNumber: this.state.isEditMeter
                          ? this.state.currentMeter?.meter_number
                          : "",
                        lastReading: this.state.isEditMeter
                          ? this.state.currentMeter?.last_reading
                          : "",
                        lastReadingDate: this.state.isEditMeter
                          ? this.state.currentMeter?.date_of_last_reading
                          : "",
                        meteerNote: this.state.isEditMeter
                          ? this.state.currentMeter?.meter_note
                          : "",
                      },
                    ],
              }}
              enableReinitialize={true}
              validateOnMount={true}
              validateOnChange={true}
              validationSchema={
                this.state.isTenant ? this.addTenantSchema : this.addMeterSchema
              }
              onSubmit={() => {
                this.setValidate(true);

                this.state.isTenant
                  ? this.createpdateTenant(
                      this.state.isEditTenant ? this.state.tenantId : undefined
                    )
                  : this.createOrUpdateMeter(
                      this.state.isEditMeter ? this.state.meterId : undefined
                    );
              }}
              data-test-id="addTenantForm"
            >
              {({ errors, values, setFieldValue, handleSubmit }) => (
                <form>
                  {console.log({
                    tenantId: this.state.tenantId,
                    isEditMeter: this.state.isEditMeter,
                    isEditTenant: this.state.isEditTenant,
                  })}
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.mainFieldControl}>
                      {this.state.isTenant ? (
                        <>
                          <label
                            style={webStyle.inputLabel}
                            htmlFor={"tenantName"}
                          >
                            {configJSON.tenantNameText}
                          </label>
                          <input
                            style={{
                              ...webStyle.inputElemnt,
                              ...(this.state.validate && errors.tenantName
                                ? webStyle.inputElementError
                                : {}),
                            }}
                            value={values.tenantName}
                            data-test-id="tenantName"
                            type="text"
                            onChange={(event) => {
                              setFieldValue("tenantName", event.target.value);
                            }}
                          />
                          {this.state.validate && errors.tenantName && (
                            <Tooltip
                              open={Boolean(errors.tenantName)}
                              title={errors.tenantName}
                              componentsProps={tooltipStyles}
                              arrow
                            >
                              <img
                                src={errorIcon}
                                alt=""
                                style={webStyle.errorIcon}
                              />
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                    <FieldArray name="meters">
                      {(arrayHelpers: any) => (
                        <>
                          {values.meters.map((meter, index) => {
                            const currentValue = values?.meters[index];
                            const currentError = (errors?.meters || [])[
                              index
                            ] as any;
                            return (
                              <Box sx={webStyle.meterBlcok} key={index}>
                                <Box sx={webStyle.meterTitle}>
                                  <Typography sx={webStyle.meterTitleLabel}>
                                    {configJSON.meterTitleText}
                                    {index + 1}
                                  </Typography>
                                  {this.state.isTenant ? (
                                    <button
                                      style={webStyle.removeMeeterButton}
                                      type="button"
                                      data-test-id={`removeItem.${index}`}
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                    >
                                      <img
                                        src={removeIcon}
                                        alt=""
                                        style={webStyle.btnIcon}
                                      />
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <label
                                    style={webStyle.radioButtonTitleLabel}
                                    htmlFor={`meters.${index}.type`}
                                  >
                                    {configJSON.typeText}
                                  </label>
                                  <Box sx={webStyle.radioButtonGroup}>
                                    <Box sx={webStyle.radioButtnBlock}>
                                      <Radio
                                        value="Water"
                                        checked={currentValue.type === "Water"}
                                        sx={webStyle.radioButton()}
                                        data-test-id={`meters.${index}.type.water`}
                                        name={`meterType.${index}`}
                                        onChange={(event) => {
                                          const element =
                                            event.currentTarget as HTMLInputElement;
                                          const value = element.value;
                                          setFieldValue(
                                            `meters.${index}.type`,
                                            value
                                          );
                                          setFieldValue(
                                            `meters.${index}.waterSubType`,
                                            "Cold"
                                          );
                                          setFieldValue(
                                            `meters.${index}.volume`,
                                            "Cu ft"
                                          );
                                          setFieldValue(
                                            `meters.${index}.metric`,
                                            "Standard"
                                          );
                                        }}
                                      />
                                      <label
                                        style={webStyle.radioButtonLabel}
                                        htmlFor="meter"
                                      >
                                        {configJSON.meterTypeText}
                                      </label>
                                    </Box>
                                    <Box sx={webStyle.radioButtnBlock}>
                                      <Radio
                                        value="Electric"
                                        checked={
                                          currentValue.type === "Electric"
                                        }
                                        sx={webStyle.radioButton()}
                                        data-test-id={`meters.${index}.type.electric`}
                                        name={`meterType.${index}`}
                                        onChange={(event) => {
                                          const element =
                                            event.currentTarget as HTMLInputElement;
                                          const value = element.value;
                                          setFieldValue(
                                            `meters.${index}.type`,
                                            value
                                          );
                                          setFieldValue(
                                            `meters.${index}.waterSubType`,
                                            ""
                                          );
                                          setFieldValue(
                                            `meters.${index}.volume`,
                                            ""
                                          );
                                          setFieldValue(
                                            `meters.${index}.metric`,
                                            ""
                                          );
                                        }}
                                      />
                                      <label
                                        style={webStyle.radioButtonLabel}
                                        htmlFor="Electric"
                                      >
                                        {configJSON.electricalTypeText}
                                      </label>
                                    </Box>
                                  </Box>
                                </Box>
                                {currentValue.type?.toLowerCase() ===
                                  "water" && (
                                  <>
                                    <Box sx={webStyle.arrayFieldControl}>
                                      <label
                                        style={webStyle.radioButtonTitleLabel}
                                        htmlFor={`meter_water_sub_type`}
                                      >
                                        {configJSON.typeText}
                                      </label>
                                      <Box sx={webStyle.radioButtonGroup}>
                                        <Box sx={webStyle.radioButtnBlock}>
                                          <Radio
                                            sx={webStyle.radioButton()}
                                            data-test-id={`meters.${index}.waterSubType.cold`}
                                            name={`meter_water_sub_type_cold`}
                                            value="Cold"
                                            checked={
                                              currentValue.waterSubType?.toLowerCase() ===
                                              "cold"
                                            }
                                            onChange={(event) => {
                                              const element =
                                                event.currentTarget as HTMLInputElement;
                                              const value = element.value;
                                              setFieldValue(
                                                `meters.${index}.waterSubType`,
                                                value
                                              );
                                            }}
                                          />
                                          <label
                                            style={webStyle.radioButtonLabel}
                                            htmlFor="meter_water_sub_type_cold"
                                          >
                                            {configJSON.coldWaterTypeText}
                                          </label>
                                        </Box>
                                        <Box sx={webStyle.radioButtnBlock}>
                                          <Radio
                                            sx={webStyle.radioButton()}
                                            data-test-id={`meters.${index}.waterSubType.hot`}
                                            name={`meter_water_sub_type_cold`}
                                            value="Hot"
                                            checked={
                                              currentValue.waterSubType?.toLowerCase() ===
                                              "hot"
                                            }
                                            onChange={(event) => {
                                              const element =
                                                event.currentTarget as HTMLInputElement;
                                              const value = element.value;
                                              setFieldValue(
                                                `meters.${index}.waterSubType`,
                                                value
                                              );
                                            }}
                                          />
                                          <label
                                            style={webStyle.radioButtonLabel}
                                            htmlFor="Hot"
                                          >
                                            {configJSON.hotWaterTypeText}
                                          </label>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box sx={webStyle.arrayFieldControl}>
                                      <label
                                        style={webStyle.radioButtonTitleLabel}
                                        htmlFor={`volume`}
                                      >
                                        {configJSON.volumeText}
                                      </label>
                                      <Box sx={webStyle.radioButtonGroup}>
                                        <Box sx={webStyle.radioButtnBlock}>
                                          <Radio
                                            sx={webStyle.radioButton()}
                                            data-test-id={`meters.${index}.volume.cu_ft`}
                                            name={`volume_cu_ft`}
                                            value="Cu ft"
                                            checked={
                                              currentValue.volume?.toLowerCase() ===
                                                "cu ft" ||
                                              currentValue.volume?.toLowerCase() ===
                                                "cu_ft"
                                            }
                                            onChange={(event) => {
                                              const element =
                                                event.currentTarget as HTMLInputElement;
                                              const value = element.value;
                                              setFieldValue(
                                                `meters.${index}.volume`,
                                                value
                                              );
                                            }}
                                          />
                                          <label
                                            style={webStyle.radioButtonLabel}
                                            htmlFor="volume_cu_ft"
                                          >
                                            {configJSON.volumeCuFtText}
                                          </label>
                                        </Box>
                                        <Box sx={webStyle.radioButtnBlock}>
                                          <Radio
                                            sx={webStyle.radioButton()}
                                            data-test-id={`meters.${index}.volume.gallons`}
                                            name={`volume_gallons`}
                                            value="Gallons"
                                            checked={
                                              currentValue.volume?.toLowerCase() ===
                                              "gallons"
                                            }
                                            onChange={(event) => {
                                              const element =
                                                event.currentTarget as HTMLInputElement;
                                              const value = element.value;
                                              setFieldValue(
                                                `meters.${index}.volume`,
                                                value
                                              );
                                            }}
                                          />
                                          <label
                                            style={webStyle.radioButtonLabel}
                                            htmlFor="Gallons"
                                          >
                                            {configJSON.volumeGallonsText}
                                          </label>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box sx={webStyle.arrayFieldControl}>
                                      <label
                                        style={webStyle.radioButtonTitleLabel}
                                        htmlFor={`metric`}
                                      >
                                        {configJSON.metricText}
                                      </label>
                                      <Box sx={webStyle.radioButtonGroup}>
                                        <Box sx={webStyle.radioButtnBlock}>
                                          <Radio
                                            sx={webStyle.radioButton()}
                                            data-test-id={`meters.${index}.metric.metric_standard`}
                                            name={`metric_standard`}
                                            value="Standard"
                                            checked={
                                              currentValue.metric?.toLowerCase() ===
                                              "standard"
                                            }
                                            onChange={(event) => {
                                              const element =
                                                event.currentTarget as HTMLInputElement;
                                              const value = element.value;
                                              setFieldValue(
                                                `meters.${index}.metric`,
                                                value
                                              );
                                            }}
                                          />
                                          <label
                                            style={webStyle.radioButtonLabel}
                                            htmlFor="Standard"
                                          >
                                            {configJSON.standardVolumeText}
                                          </label>
                                        </Box>
                                        <Box sx={webStyle.radioButtnBlock}>
                                          <Radio
                                            sx={webStyle.radioButton()}
                                            data-test-id={`meters.${index}.metric.metric_10cu_ft`}
                                            name={`metric_10cu_ft`}
                                            value="10 cu.ft/gallons"
                                            checked={
                                              currentValue.metric?.toLowerCase() ===
                                                "10 cu.ft/gallons" ||
                                              currentValue.metric?.toLowerCase() ===
                                                "ten_cuft_gallons"
                                            }
                                            onChange={(event) => {
                                              const element =
                                                event.currentTarget as HTMLInputElement;
                                              const value = element.value;
                                              setFieldValue(
                                                `meters.${index}.metric`,
                                                value
                                              );
                                            }}
                                          />
                                          <label
                                            style={webStyle.radioButtonLabel}
                                            htmlFor="metric_10cu_ft"
                                          >
                                            {configJSON.type1VolumeText}
                                          </label>
                                        </Box>
                                        <Box sx={webStyle.radioButtnBlock}>
                                          <Radio
                                            sx={webStyle.radioButton()}
                                            data-test-id={`meters.${index}.metric.metric_100cu_ft`}
                                            name={`metric_100cu_ft`}
                                            value="100 cu.ft/gallons"
                                            checked={
                                              currentValue.metric?.toLowerCase() ===
                                                "100 cu.ft/gallons" ||
                                              currentValue.metric?.toLowerCase() ===
                                                "hundred_cuft_gallons"
                                            }
                                            onChange={(event) => {
                                              const element =
                                                event.currentTarget as HTMLInputElement;
                                              const value = element.value;
                                              setFieldValue(
                                                `meters.${index}.metric`,
                                                value
                                              );
                                            }}
                                          />
                                          <label
                                            style={webStyle.radioButtonLabel}
                                            htmlFor="metric_100cu_ft"
                                          >
                                            {configJSON.type2VolumeText}
                                          </label>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </>
                                )}
                                <Box sx={webStyle.arrayFieldControl}>
                                  <label
                                    style={webStyle.inputLabel}
                                    htmlFor={`meters.${index}.meterNumber`}
                                  >
                                    {configJSON.meterNumberText}
                                  </label>
                                  <input
                                    style={{
                                      ...webStyle.inputElemnt,
                                      ...(this.state.validate &&
                                      currentError?.meterNumber
                                        ? webStyle.inputElementError
                                        : {}),
                                    }}
                                    data-test-id={`meters.${index}.meterNumber`}
                                    type="text"
                                    value={currentValue.meterNumber || ""}
                                    onChange={(event) => {
                                      setFieldValue(
                                        `meters.${index}.meterNumber`,
                                        event.target.value
                                      );
                                    }}
                                  />
                                  {this.state.validate &&
                                    currentError?.meterNumber && (
                                      <Tooltip
                                        open={Boolean(
                                          currentError?.meterNumber
                                        )}
                                        title={currentError?.meterNumber}
                                        componentsProps={tooltipStyles}
                                        arrow
                                      >
                                        <img
                                          src={errorIcon}
                                          alt=""
                                          style={webStyle.errorIcon}
                                        />
                                      </Tooltip>
                                    )}
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <label
                                    style={webStyle.inputLabel}
                                    htmlFor={`meters.${index}.lastReading`}
                                  >
                                    {configJSON.lastReadingText}
                                  </label>
                                  <input
                                    style={{
                                      ...webStyle.inputElemnt,
                                      ...(this.state.validate &&
                                      currentError?.lastReading
                                        ? webStyle.inputElementError
                                        : {}),
                                    }}
                                    data-test-id={`meters.${index}.lastReading`}
                                    type="text"
                                    value={currentValue.lastReading || ""}
                                    onChange={(event) => {
                                      setFieldValue(
                                        `meters.${index}.lastReading`,
                                        event.target.value
                                      );
                                    }}
                                  />
                                  {this.state.validate &&
                                    currentError?.lastReading && (
                                      <Tooltip
                                        open={Boolean(
                                          currentError?.lastReading
                                        )}
                                        title={currentError?.lastReading}
                                        componentsProps={tooltipStyles}
                                        arrow
                                      >
                                        <img
                                          src={errorIcon}
                                          alt=""
                                          style={webStyle.errorIcon}
                                        />
                                      </Tooltip>
                                    )}
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <label
                                    style={webStyle.inputLabel}
                                    htmlFor={`meters.${index}.lastReadingDate`}
                                  >
                                    {configJSON.lastReadingDateText}
                                  </label>
                                  {console.log({
                                    asd: currentValue?.lastReadingDate,
                                  })}
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    {console.log({ currentError })}
                                    <DatePicker
                                      sx={{
                                        ...webStyle.inputDatePickerElemnt,
                                        ...(this.state.validate &&
                                        currentError?.lastReadingDate
                                          ? webStyle.inputElementError
                                          : {}),
                                      }}
                                      data-test-id={`meters.${index}.lastReadingDate`}
                                      value={dayjs(
                                        currentValue?.lastReadingDate
                                      )}
                                      onChange={(newValue) => {
                                        const dateString = (
                                          newValue as Dayjs
                                        ).format("YYYY-MM-DD");
                                        setFieldValue(
                                          `meters.${index}.lastReadingDate`,
                                          dateString
                                        );
                                      }}
                                    />
                                  </LocalizationProvider>
                                  {this.state.validate &&
                                    currentError?.lastReadingDate && (
                                      <Tooltip
                                        open={Boolean(
                                          currentError?.lastReadingDate
                                        )}
                                        title={currentError?.lastReadingDate}
                                        componentsProps={tooltipStyles}
                                        arrow
                                      >
                                        <img
                                          src={errorIcon}
                                          alt=""
                                          style={{
                                            ...webStyle.errorIcon,
                                            right: 46,
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <label
                                    style={webStyle.inputLabel}
                                    htmlFor={`meter_note`}
                                  >
                                    {configJSON.meterNoteText}
                                  </label>
                                  <input
                                    style={{
                                      ...webStyle.inputElemnt,
                                      ...(this.state.validate &&
                                      currentError?.meteerNote
                                        ? webStyle.inputElementError
                                        : {}),
                                    }}
                                    data-test-id={`meters.${index}.meteerNote`}
                                    type="text"
                                    value={currentValue.meteerNote || ""}
                                    onChange={(event) => {
                                      setFieldValue(
                                        `meters.${index}.meteerNote`,
                                        event.target.value
                                      );
                                    }}
                                  />
                                </Box>
                              </Box>
                            );
                          })}
                          {this.state.isTenant && !this.state.isEditTenant ? (
                            <Box sx={webStyle.fieldControl}>
                              <button
                                style={webStyle.addNewMeterButton}
                                type="button"
                                data-test-id={"addNewArrayItem"}
                                onClick={() => {
                                  arrayHelpers.push({
                                    type: "Water",
                                    waterSubType: "Cold",
                                    volume: "Cu ft",
                                    metric: "Standard",
                                    meterNumber: "",
                                    lastReading: "",
                                    lastReadingDate: "",
                                    meteerNote: "",
                                  });
                                }}
                              >
                                <img
                                  src={addIcon}
                                  alt=""
                                  style={webStyle.btnIcon}
                                />
                                <Typography
                                  style={webStyle.typographyTitleText}
                                >
                                  {configJSON.addNewMeterText}
                                </Typography>
                              </button>
                            </Box>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </FieldArray>
                    <Box sx={webStyle.fieldControlGroup}>
                      <button
                        style={webStyle.cancelButton}
                        data-test-id="cancelBtn"
                        type="button"
                        onClick={() => {
                          this.state.isTenant
                            ? this.goTenantsPage()
                            : this.goMetersPage();
                        }}
                      >
                        {configJSON.cancelBtnText}
                      </button>
                      <button
                        style={webStyle.submitButton}
                        data-test-id="submitBtn"
                        type="button"
                        onClick={() => {
                          this.state.isTenant
                            ? this.setTenantData({
                                tenantName: values?.tenantName,
                                metersAttributes: values?.meters,
                              })
                            : this.setMetersData(values?.meters[0]);

                          this.setValidate(true);
                          if (Object.keys(errors).length == 0) {
                            handleSubmit();
                          }
                        }}
                      >
                        {configJSON.saveBtnText}
                      </button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const tooltipStyles = {
  tooltip: {
    sx: {
      color: "#FFFFFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: "13px",
      lineHeight: "18px",
      fontWeight: "400",
      padding: "8px 12px",
      backgroundColor: "#1E293B",
      borderRadius: "8px",
      width: "fit-content",
      maxWidth: "480px",
      "& .MuiTooltip-arrow": {
        color: "#1E293B",
      },
    },
  },
};

const webStyle = {
  addTenantsContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
    minHeight: "100%",
  },
  addTenantsBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  addTenantFormContainer: {
    maxWidth: "600px",
    width: "80%",
    margin: "40px auto 0",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "10px",
    wordBreak: "break-word",
  },
  formSubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
    wordBreak: "break-word",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  mainFieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px 0px",
    position: "relative",
  },
  arrayFieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px 0px",
    position: "relative",
  },
  radioButtonGroup: {
    display: "flex",
    justifyContent: "space-between",
  },
  fieldControlGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  fieldCheckboxControl: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    margin: "10px",
    position: "relative",
  },
  switchTitle: {
    alignItems: "center",
    display: "flex",
  },
  radioButtonTitleLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#64748B",
    margin: "10px 0px",
    alignItems: "center",
    display: "flex",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  radioButtonLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
    marginLeft: "8px",
  },
  radioButtnBlock: {
    display: "flex",
    flexDirection: "row",
    margin: "10px 0px",
    alignItems: "center",
    flex: "auto",
    width: "50%",
  },
  numberInputElemnt: {
    height: "56px",
    padding: "10px",
    border: "none",
    "-moz-appearance": "textfield",
    "& ::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& ::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    width: "34%",
    textAlign: "center" as const,
  },
  inputElemnt: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  inputElementError: {
    border: "1px solid #F87171",
  },
  radioButtonBlock: {
    display: "flex",
    alignItems: "center",
    marginBottom: "11px",
  },
  radioButton: (isSmall?: boolean) => ({
    padding: 0,
    width: isSmall ? "16px" : "20px",
    height: isSmall ? "16px" : "20px",
    "& .MuiSvgIcon-root": {
      color: "#64748B",
      width: isSmall ? "20px" : "24px",
      height: isSmall ? "20px" : "24px",
    },
    "&.Mui-checked": {
      backgroundColor: "#1A7BA4",
      "& .MuiSvgIcon-root": {
        width: isSmall ? "16px" : "20px",
        height: isSmall ? "16px" : "20px",
      },
      "& .MuiSvgIcon-root:first-of-type": {
        color: "#1A7BA4",
      },
      "& .MuiSvgIcon-root:nth-of-type(2)": {
        color: "#FFFFFF",
      },
    },
  }),
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  numberFieldControlIcon: {
    width: "24px",
    height: "24px",
  },
  numberFieldControlButton: {
    color: "#1A7BA41A",
    border: "none",
    width: "33%",
  },
  cancelButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: "30px",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#FFFFFF",
    color: "#1A7BA4",
    padding: "0px 16px",
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "30px 0 0 16px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 16px",
  },
  meterToReadBlcok: {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
  },
  meterBlcok: {
    border: "1px solid #1A7BA44D",
    backgroundColor: "#1A7BA40F",
    padding: "16px",
    borderRadius: "12px",
    margin: "20px 0px",
  },
  meterTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  meterTitleLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "18px",
    fontWeight: 700,
    lineheight: "24px",
    color: "#0F172A",
  },
  addNewMeterButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "inherit",
    color: "#1A7BA4",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  removeMeeterButton: {
    height: "36px",
    width: "46px",
    border: "none",
    backgroundColor: "inherit",
  },
  typographyTitleText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  calendarIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  inputDatePickerElemnt: {
    height: "56px",
    padding: "0px",
    backgroundColor: "#FFFFFF",
    "& fieldset": {
      border: "1px solid #CBD5E1 !important",
      borderRadius: "8px",
    },
    "& input": {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#0F172A",
      padding: "10px",
      height: "36px",
    },
  },
};
// Customizable Area End
