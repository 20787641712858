Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.addOrUpdateTenantApiEndPoint = "bx_block_custom_forms/properties";
exports.addOrUpdateTenantApiContentType = "application/json";

exports.addOrUpdateMetersApiEndPoint = "bx_block_custom_forms/properties";
exports.addOrUpdateMetersApiContentType = "application/json";

exports.showPropertyApiEndPoint = "bx_block_custom_forms/properties";
exports.showPropertyApiMethod = "GET";
exports.showPropertyApiContentType = "application/json";

exports.showTenantApiContentType = "application/json";
exports.showTenantApiMethod = "GET";
exports.showTenantApiEndPoint = "bx_block_custom_forms/properties";

exports.showMeterApiContentType = "application/json";
exports.showMeterApiMethod = "GET";
exports.showMeterApiEndPoint = "bx_block_custom_forms/properties";

exports.editTenantText = "Edit Tenant";
exports.editMeterText = "Edit Meter";

exports.typeText = "Type of meter";
exports.meterNumberText = "Meter number";
exports.lastReadingText = "Last reading";
exports.lastReadingDateText = "Date of last reading";
exports.meterNoteText = "Meter note (eg.: Meter location, access code)";
exports.addNewMeterText = "Add meter";

exports.meterTypeText = "Water";
exports.electricalTypeText = "Electrical";

exports.typeText = "Type";
exports.coldWaterTypeText = "Cold";
exports.hotWaterTypeText = "Hot";

exports.volumeText = "Volume";
exports.volumeCuFtText = "Cu ft";
exports.volumeGallonsText = "Gallons";


exports.metricText = "Metric";
exports.standardVolumeText = "Standard";
exports.type1VolumeText = "10 cu.ft/gallons";
exports.type2VolumeText = "100 cu.ft/gallons";
exports.type3VolumeText = "100 cu ft";

exports.meterTitleText = "Meter #";
exports.cancelBtnText = "Cancel";
exports.saveBtnText = "Save";
exports.tenantNameText = "Tenant name";
// Customizable Area End