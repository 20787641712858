export const searchIcon = require("../assets/search.svg");
export const filterIcon = require("../assets/filter.svg");
export const addIcon = require("../assets/add.svg");
export const editIcon = require("../assets/edit.svg");
export const deleteIcon = require("../assets/delete.svg");
export const leftArrowIcon = require("../assets/leftArrow.svg");

export const hotWaterIcon = require("../assets/hotWaterIcon.png");
export const coldWaterIcon = require("../assets/coldWaterIcon.png");

export const noMetersIcon = require("../assets/noMeters.svg");