Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.addPropertyApiEndPoint = "bx_block_custom_forms/properties";
exports.addPropertyApiMethod = "POST";
exports.addPropertyApiContentType = "application/json";

exports.updatePropertyApiEndPoint = "bx_block_custom_forms/properties";
exports.updatePropertyApiMethod = "PUT";
exports.updatePropertyApiContentType = "application/json";

exports.showPropertyApiEndPoint = "bx_block_custom_forms/properties";
exports.showPropertyApiMethod = "GET";
exports.showPropertyApiContentType = "application/json";

exports.addPropertyText = "Add property";
exports.enterPropertiydetailsText = "Enter the property details";
exports.editPropertyText = "Edit property";
exports.updatePropertiydetailsText = "Update the property details";
exports.propertyNameText = "Property name";
exports.serviceAddressText = "Service address";
exports.meterLocationText = "Meter location";

exports.buildingManagerText = "Building manager / Supt’s name";
exports.phoneNumberText = "Phone number";
exports.emailText = "Email";
exports.meterOfReadText = "# of meters to read";
exports.readingPerionText = "Reading period";
exports.startReadingFronText = "Start reading from";
exports.receiveReportText = "Receive report by";
exports.getInvoiceText = "Get invoice for my tenants";
exports.addServiceText = "Add service charge to invoice";
exports.cancelText = "Cancel";
exports.saveText = "Save";
// Customizable Area End