Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getTenantsApiContentType = "application/json";
exports.getTenantsApiMethod = "GET";
exports.getTenantsApiEndPoint = "bx_block_custom_forms/properties";

exports.showPropertyApiEndPoint = "bx_block_custom_forms/properties";
exports.showPropertyApiMethod = "GET";
exports.showPropertyApiContentType = "application/json";

exports.deleteTenantApiMethod = "DELETE";
exports.deleteTenantApiEndPoint = "bx_block_custom_forms/properties";

exports.noTenantTitleText = "No tenants";
exports.noTenantSubTitleText = "Click on the button below to start adding your tenants";

exports.backtoPropertiesText = "Back to properties";
exports.addMeterText = "Add meter";
exports.addTenantText = "Add tenant";
exports.tenantNameText = "Tenant name";
exports.numberOfMetersText = "# of meters";
exports.actionsText = "Actions";
exports.editText = "Edit";
exports.deleteText = "Delete";
exports.serachPlaceholderText = "Search";
exports.cancelText = "Cancel";
exports.dialogTitle ="Are you sure you want to delete this tenant?";
exports.dialogContent = "This action can’t be undone, and all the data linked to this tenant will be permanently deleted and no longer accessible.";
// Customizable Area End